import axios from 'axios';
import router from '@/router/index';
import i18n from '@/locale/index';
import { message } from 'ant-design-vue';

const api = axios.create({
    baseURL: "/api",
});
api.defaults.withCredentials = true;
api.interceptors.request.use( config => {
    return config;
}, err => {
    Promise.reject(err);
});

api.interceptors.response.use( res => {
    if( res.status == 200 ) {
        var res_data = res.data;
        if( !res_data.hasOwnProperty("code") ) {
            return Promise.reject(res);
        }
        var code = res_data.code;
        if( code != 20000 ) {
            message.error(i18n.global.t('error.'+res.data.msg));
            return Promise.reject(res);
        }
        return Promise.resolve(res.data);
    }

    return Promise.reject(res);
}, err => {
    if( err.response.status == 401 ) {
        message.info(i18n.global.t('error.'+err.response.data.msg));
        router.push({ path: '/login' });
    } else {
        message.error(i18n.global.t('error.something wrong'));
    }
    return Promise.reject(err);
});

export const authLogin = (params) => api.post("/auth/login", params);
export const authLogout = () => api.get("/auth/logout");
export const getMenu = () => api.get("/get_menu");

export const adminUserSearchData = (params) => api.get("/admin_user/searchdata", {params});
export const adminUser = (params) => api.get("/admin_user", {params});
export const adminUserAdd = (params) => api.post("/admin_user/add", params);
export const adminUserEdit = (params) => api.post("/admin_user/edit", params);
export const adminUserDelete = (params) => api.post("/admin_user/delete", params);

export const adminRole = (params) => api.get("/admin_role", {params});
export const adminRoleAdd = (params) => api.post("/admin_role/add", params);
export const adminRoleEdit = (params) => api.post("/admin_role/edit", params);
export const adminRoleDelete = (params) => api.post("/admin_role/delete", params);
export const adminRoleGetAction = (params) => api.post("/admin_role/get_action", params);

export const adminAction = (params) => api.get("/admin_action", {params});
export const adminActionAdd = (params) => api.post("/admin_action/add", params);
export const adminActionEdit = (params) => api.post("/admin_action/edit", params);
export const adminActionDelete = (params) => api.post("/admin_action/delete", params);

export const masseur = (params) => api.get("/masseur", {params});
export const masseurAdd = (params) => api.post("/masseur/add", params);
export const masseurEdit = (params) => api.post("/masseur/edit", params);
export const masseurDelete = (params) => api.post("/masseur/delete", params);

export const program = (params) => api.get("/program", {params});
export const programAdd = (params) => api.post("/program/add", params);
export const programEdit = (params) => api.post("/program/edit", params);
export const programDelete = (params) => api.post("/program/delete", params);

export const orderSearchData = (params) => api.get("/order/searchdata", {params});
export const order = (params) => api.get("/order", {params});
export const orderAdd = (params) => api.post("/order/add", params);
export const orderEdit = (params) => api.post("/order/edit", params);
export const orderDelete = (params) => api.post("/order/delete", params);
export const orderCheckGetIn = (params) => api.post("/order/check_get_in", params);
export const orderCheckGetMoney = (params) => api.post("/order/check_get_money", params);

export const fromPlatform = (params) => api.get("/from_platform", {params});
export const fromPlatformAdd = (params) => api.post("/from_platform/add", params);
export const fromPlatformEdit = (params) => api.post("/from_platform/edit", params);
export const fromPlatformDelete = (params) => api.post("/from_platform/delete", params);