<template>
    <div class="lang_dropdown">
        <a-dropdown class="lang">
            <template #overlay>
                <a-menu @click="changeLocal">
                    <a-menu-item v-for="(val,key) in langArr" :key="key">{{val}}</a-menu-item>
                </a-menu>
            </template>
            <a-button>
                {{langText}}
                <DownOutlined />
            </a-button>
        </a-dropdown>
    </div>
</template>

<script>
    import './lang_dropdown.scss';
    import { ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { DownOutlined } from '@ant-design/icons-vue';
    
    export default {
        name: 'LangDropdown',
        components: {
            DownOutlined,
        },
        setup() {
            const { t, locale } = useI18n();

            const langArr = ref({
                "tw": "繁體中文",
            })
            const langText = ref("繁體中文");

            const changeLocal = e => {
                const lang = e.key;
                langText.value = langArr.value[lang];
                locale.value = lang;
                localStorage.setItem("locale", lang);
            }

            return {
                langArr, langText, changeLocal, 
            }
        }
    };
</script>