import { createI18n } from "vue-i18n";
import zh_TW from './zh_TW.json';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw';

dayjs.locale('zh-tw');

const i18n = createI18n({
    locale: localStorage.getItem("locale") ? localStorage.getItem("locale"): "zh_TW",
    globalInjection: true,
    legacy: false,
    fallbackLocale: "zh_TW",
    messages: {
        zh_TW: {...zh_TW},
    }
})
export default i18n;