<template>
    <div class="left_menu">
        <a-menu theme="dark" mode="inline" class="menu_item" :openKeys="openKeys" :selectedKeys="selectedKeys" @click="menuItemClick">
            <LeftMenuItem v-for="(obj, key) in menu" :item="obj" />
        </a-menu>
    </div>
</template>

<script>
    import './left_menu.scss';
    import { onBeforeMount, ref } from 'vue';
    import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
    import { getMenu } from '@/api-core/api-core';
    import LeftMenuItem from './left_menu_item.vue';
    
    export default {
        name: 'LeftMenu',
        components: {
            LeftMenuItem, 
        },
        setup(props, context) {
            const route = useRoute();
            const router = useRouter();

            const menu = ref([]);
            const selectedKeys = ref([]);
            const openKeys = ref([]);

            onBeforeMount(() => {
                getMenu().then(res => {
                    menu.value = res.data;
                    const path = route.path.replace("/", "");
                    for( var key in menu.value ) {
                        setMenu(menu.value[key], path, key);
                    }
                })
            });

            onBeforeRouteUpdate( to => {
                const path = to.path.replace("/", "");
                for( var key in menu.value ) {
                    setMenu(menu.value[key], path, key);
                }
            });

            const setMenu = (item, path, topkey) => {
                if(item.data.url == path && path != "") {
                    selectedKeys.value = [path];
                    setPageAction(item);
                    return true;
                } else {
                    for(var key in item.children) {
                        var match = setMenu(item.children[key], path, topkey);
                        if(match) {
                            openKeys.value = [item.title];
                        }
                    }
                }
            };

            const setPageAction = (item) => {
                var tmp = [];
                if( item.children != null ) {
                    var diffStr = item.data.url + "/";
                    for(var key in item.children) {
                        if( item.children[key].checked ) {
                            var str = item.children[key].data.url;
                            str = str.replace(diffStr, "");
                            tmp.push(str);
                        }
                    }
                }
                context.emit("setPageAction", tmp);
            };

            const menuItemClick = (item) => {
                selectedKeys.value = [item.key];
                router.push({ path: item.key });
            };

            return {
                menu, selectedKeys, openKeys, menuItemClick, 
            }
        }
    };
</script>