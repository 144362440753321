import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index';
import Login from '@/views/login/login';
import Home from '@/views/home/home';
import Main from '@/views/layouts/main/main';

const routes = [
  {
    path: '/admin_login',
    name: 'AdminLogin',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: "Main",
    component: Main,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/admin_user',
        component: () => import("@/views/systemManager/admin_user/admin_user.vue"),
      },
      {
        path: '/admin_role',
        component: () => import("@/views/systemManager/admin_role/admin_role.vue"),
      },
      {
        path: '/admin_action',
        component: () => import("@/views/systemManager/admin_action/admin_action.vue"),
      },
      {
        path: '/masseur',
        component: () => import("@/views/storeManager/masseur/masseur.vue"),
      },
      {
        path: '/program',
        component: () => import("@/views/storeManager/program/program.vue"),
      },
      {
        path: '/from_platform',
        component: () => import("@/views/storeManager/from_platform/from_platform.vue"),
      },
      {
        path: '/order',
        component: () => import("@/views/reportManager/order/order.vue"),
      },
      {
        path: '/report',
        component: () => import("@/views/reportManager/report/report.vue"),
      },
      {
        path: '/:pathMatch(.*)*',
        component: () => import("@/views/errors/404/404.vue"),
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if( to.meta.auth ) {
    var ok = store.state.user.isLogin;
    if( ok ) {
      next();
    } else {
      next("/login");
    }
  }
  next();
});

export default router
