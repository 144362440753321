<template>
    <div v-if="item.data.url == ''">
        <a-sub-menu :key="item.title" :title="$t('left_menu.'+item.title)">
            <LeftMenuItem v-if="item.children != null" v-for="obj in item.children" :item="obj" />
        </a-sub-menu>
    </div>
    <div v-else-if="item.data.type != 0">
        <a-menu-item class="menu_item" :key="item.data.url">{{ $t('left_menu.'+item.title) }}</a-menu-item>
    </div>
</template>

<script>
export default {
    name: "LeftMenuItem",
    props: {
        item: { type: Object, default: {} },
    },
}
</script>