import { authLogin, authLogout } from '@/api-core/api-core';

export default {
    namespaced: true,
    state: {
        isLogin: false,
        user_id: null,
        username: "",
        nickname: "",
        up_user_name: "",
        identity: null,
    },
    mutations: {
        setUser(state, data) {
            console.log(data);
            state.isLogin = true;
            state.user_id = data.id || null;
            state.username = data.username || "";
            state.nickname = data.nickname || "";
            state.up_user_name = data.up_user_name || "";
            state.identity = data.identity;
        },
        removeUser(state) {
            state.isLogin = false;
            state.user_id = null;
            state.username = "";
            state.nickname = "";
            state.up_user_name = "";
            state.identity = null;
        }
    },
    actions: {
        login({commit}, values) {
            return authLogin(values).then(res => {
                if( res.code == 20000 ) {
                    commit("setUser", {identity: res.identity, ...res.data});
                    return Promise.resolve(res.data);
                } else {
                    commit("removeUser");
                    return Promise.reject(res.msg);
                }
            }).catch(err => {
                commit("removeUser");
                return Promise.reject(err);
            })
        },
        logout({commit}) {
            authLogout().finally(() => {
                commit("removeUser");
            });
        }
    },
    getters: {
        
    }
}