<template>
    <div class="top_menu">
        <div class="logo" @click="onClickLogo">{{$t('top_menu.Admin System')}}</div>
        <div class="rightDiv">
            <div class="userInfo">
                <a-space>
                    <u v-if="states.user_identity == 'proxy'">
                        {{ $t('top_menu.up_account') }}&nbsp;:&nbsp;
                        <span class="value">{{ states.up_user_name }}</span>
                    </u>
                    <u>
                        {{ $t('top_menu.login_account') }}&nbsp;:&nbsp;
                        <span class="value">{{ states.nickname }}</span>
                    </u>
                    <u v-if="states.user_identity == 'proxy'">
                        {{ $t('top_menu.uPoints') }}&nbsp;:&nbsp;
                        <span class="value">{{ 0 }}</span>
                    </u>
                </a-space>
            </div>
            <LangDropdown />
            <a-button type="text" @click="onClickLogout" class="logoutBtn">{{$t('top_menu.logout')}}</a-button>
        </div>
    </div>
</template>

<script>
import './top_menu.scss';
import { reactive, computed } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import LangDropdown from '@/components/lang_dropdown/lang_dropdown.vue';
import { message } from 'ant-design-vue';
    
export default {
    name: 'TopMenu',
    components: {
        LangDropdown, 
    },
    setup() {
        const store = useStore();
        const { t } = useI18n();
        const router = useRouter();
        
        const states = reactive({
            up_user_name : computed(() => store.state.user.up_user_name),
            nickname : computed(() => store.state.user.nickname),
            user_identity: computed(()=> store.state.user.identity),
        });

        const onClickLogo = () => {
            window.location.reload();
        };

        const onClickLogout = () => {
            store.dispatch("user/logout")
            .then(() => {
                message.success(t("top_menu.logout success"));
            })
            .finally(() => {
                router.push({ path: "/login" });
            });
        };

        return {
            onClickLogo, onClickLogout, states, 
        }
    }
};
</script>