<template>
    <div class="main">
        <a-layout class="main-layout">
            <a-layout-header>
                <TopMenu />
            </a-layout-header>
            <a-layout>
                <a-layout-sider width="200" breakpoint="lg" collapsed-width="0">
                    <LeftMenu @setPageAction="setPageAction" />
                </a-layout-sider>
                <a-layout-content>
                    <router-view :page_action="pageAction" />
                </a-layout-content>
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
    import './main.scss';
    import { ref } from 'vue';
    import TopMenu from '@/views/layouts/top_menu/top_menu.vue';
    import LeftMenu from '@/views/layouts/left_menu/left_menu.vue';

    
    export default {
        name: 'Main',
        components: {
            TopMenu, LeftMenu, 
        },
        setup() {

            const pageAction = ref([]);
            const setPageAction = (value) => {
                pageAction.value = value;
            };

            return {
                setPageAction, pageAction, 
            }
        }
    };
</script>