<template>
    <div class="home">
        home
    </div>
</template>

<script>
    import './home.scss';
    
    export default {
        name: 'Home',
        components: {

        },
        setup() {
            
        }
    };
</script>